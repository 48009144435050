/* ––––––––––BASIC ELEMENTS–––––––––– */

footer {
  display: flex;
  box-sizing: border-box;
  height: 126px;

  padding: 0 20px 16px 20px;

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
  footer {
    padding: 0 46px 26px 46px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
