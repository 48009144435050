/* ––––––––––BASIC ELEMENTS–––––––––– */
header {
  height: 126px;
  box-sizing: border-box;
  padding: 16px 20px 0 20px;
}

header nav {
  float: right;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
  header {
    padding: 30px 52px 0 52px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
