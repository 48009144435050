/* ––––––––––TYPOGRAPHY–––––––––– */
body {
  font-family: signo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

a {
  text-decoration: none;
  color: inherit;
}

p,
a {
  font-size: 20px;
  line-height: 1.2;
}

span,
p {
  text-align: center;
}

.font-l {
  font-size: 32px;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
  .font-l {
    font-size: 72px;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
  .font-l {
    font-size: 96px;
  }
}
