/* –––––––––––––––––––––––––––––– GENERAL –––––––––––––––––––––––––––––– */
html,
body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  background-color: $color-background;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

img {
  // width: 100%; //attention, images will be stretched and might appear pixelated
  max-width: 100%;
}

.desktop {
  display: none;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
